import {
  GenerateContentRequest,
  GenerateContentResponse,
} from "../../generated/pear-library/generate.pb";
import { Product } from "./content";
import { ProductContentType } from "./generate";
import { RequestOptions } from "./utils";

// Pure types must be `type` exported. Enums must be exported normally. Not
// doing so will cause Vite in Pear UI to throw confusing errors stating the
// type/enum is not exported.

export {
  ProductContentType,
  ProductContentType_value,
} from "../../generated/pear/content.pb";

export {
  type GenerateContentRequest,
  type GenerateContentRequestGenerateContent,
  ReadingLevelDifferentiation,
  type GenerateContentRequestDeckConfig as DeckConfig,
  DeckLessonType,
  DeckSourceIntent,
  type GenerateContentRequestPracticeSetConfig as PracticeSetConfig,
  type GenerateContentRequestAssessmentConfig as AssessmentConfig,
  AssessmentDifficulty,
  AssessmentDOK,
  AssessmentItemType,
  type GenerateContentResponse,
  GenerateContentResponseStatus,
} from "../../generated/pear-library/generate.pb";

export { QuestionType as PracticeSetQuestionType } from "../../generated/playtime-library/practice-set-items.pb";

export interface IGenerate {
  /**
   * Sends a request to the centralized content generation service. Multiple
   * pieces of content can be generated in a single request.
   * @param req The request object that will be sent to the service.
   * @param options Options for the request.
   * @returns An array of metadata about the generated content(s), one entry per
   * piece of content.
   */
  generateContent: (
    req: GenerateContentRequest,
    options?: GenerateContentRequestOptions
  ) => Promise<GenerateContentResponse[]>;
  /**
   * Shows the shared generate content drawer.
   * @param options Options for the drawer.
   */
  showGenerateContentDrawer: (
    options: ShowGenerateContentDrawerOptions
  ) => void;
}

export type GenerateContentRequestOptions = RequestOptions & {
  /**
   * Callback function that will be called once per piece of content requested
   * as response messages are received from the service. Useful for driving UI
   * updates as content is generated. All response statuses are returned. If
   * only the final item responses are needed, filter for `STATUS_ITEM_COMPLETE`
   * and `STATUS_ITEM_ERROR` or just await the promise.
   */
  onResponse?: (response: GenerateContentResponse) => void;
};

export type ShowGenerateContentDrawerOptions = {
  /**
   * The element that the drawer iframe will be appended to.
   */
  target: HTMLElement;
  /**
   * Callback function that will be called when the user clicks the close button
   * in the drawer.
   */
  onClickClose: () => void;
  /**
   * Callback function that will be called when the user clicks the top-level
   * back button in the drawer. If not provided, the top-level back button will
   * not be displayed. This option is useful if the generate content drawer is
   * opened from within a modal or flyout and the user needs a back button to
   * return to where they started.
   */
  onClickBack?: () => void;
  /**
   * Callback function that will be called when the user clicks the generate
   * button in the drawer. Useful for product UI changes and event tracking.
   */
  onClickGenerate?: (request: GenerateContentRequest) => void;
  /**
   * Callback function that will be called when content has been generated.
   * Useful for product UI changes and event tracking.
   */
  onContentGenerated?: (responses: GenerateContentResponse[]) => void;
  /**
   * Context from the current page formatted as unstructured text. The context
   * will be passed to the LLM prompt.
   */
  context?: string;
  /**
   * If provided, the drawer will skip the initial content type selection screen
   * and open directly to the "Pear Lesson Package" form with the provided
   * content type(s) selected. Important caveats for this option:
   *  1. This option does not prevent the user from selecting other content
   *     types in the form.
   *  2. If the user is missing an account for a provided content type, that
   *     content type will not be selected in the form.
   *  3. If an array containing zero or one content types is provided, the
   *     "Pear Lesson Package" form will still be displayed.
   */
  contentTypes?: ProductContentType[];
  /**
   * If true, the advanced options section will be expanded by default.
   */
  expandAdvancedOptions?: boolean;
  /**
   * If true, the "Include context from page" checkbox will be hidden. This does
   * not prevent context from being passed to the LLM prompt via the `context`
   * option.
   */
  hideIncludeContextCheckbox?: boolean;
  /**
   * If provided, the drawer will be pre-populated with the specified grade
   * level. Must be a valid PDL Unified Academic Standards grade level ID.
   */
  gradeLevelId?: string;
  /**
   * If provided, the drawer will be pre-populated with the specified standards.
   * Must be valid PDL Unified Academic Standards standard IDs.
   */
  standardIds?: string[];
  /**
   * If provided, the drawer will be pre-populated with the specified subject.
   * Must be a valid PDL Unified Academic Standards subject ID.
   */
  subjectId?: string;
};

/**
 * GenerateContentDrawerIFrameOptions is a derivative of
 * `ShowGenerateContentDrawerOptions` that can be sent across the iframe
 * boundary.
 */
export type GenerateContentDrawerIFrameOptions = Pick<
  ShowGenerateContentDrawerOptions,
  | "context"
  | "contentTypes"
  | "expandAdvancedOptions"
  | "hideIncludeContextCheckbox"
  | "gradeLevelId"
  | "standardIds"
  | "subjectId"
> & {
  product: Product;
  showBackButton: boolean;
};

export enum GenerateContentDrawerMessageType {
  ClickBack = "GENERATE_CONTENT_DRAWER_CLICK_BACK",
  ClickClose = "GENERATE_CONTENT_DRAWER_CLICK_CLOSE",
  ClickGenerate = "GENERATE_CONTENT_DRAWER_CLICK_GENERATE",
  ContentGenerated = "GENERATE_CONTENT_DRAWER_CONTENT_GENERATED",
  Initialized = "GENERATE_CONTENT_DRAWER_INITIALIZED",
  OpenStandardsModal = "GENERATE_CONTENT_DRAWER_OPEN_STANDARDS_MODAL",
  OpenStandardsModalResponse = "GENERATE_CONTENT_DRAWER_STANDARDS_MODAL_RESPONSE",
  SelectedOptions = "GENERATE_CONTENT_DRAWER_SELECTED_OPTIONS",
  OpenProductContentModal = "GENERATE_CONTENT_DRAWER_OPEN_PRODUCT_CONTENT_MODAL",
  OpenProductContentModalResponse = "GENERATE_CONTENT_DRAWER_PRODUCT_CONTENT_MODAL_RESPONSE",
  OpenInitializeProductModal = "GENERATE_CONTENT_DRAWER_OPEN_INITIALIZE_PRODUCT_MODAL",
  OpenInitializeProductModalResponse = "GENERATE_CONTENT_DRAWER_INITIALIZE_PRODUCT_MODAL_RESPONSE",
}

export const GENERATE_CONTENT_TOPIC_MAX_LENGTH = 4_096;
export const GENERATE_CONTENT_CONTEXT_MAX_LENGTH = 16_384;
