import {
  GetContentLLMContextResponse,
  GetContentPreviewResponse,
} from "../../generated/pear-library/content.pb";
import { SearchContentEntity } from "./content";

export {
  type SearchContentRequest,
  type SearchContentResponse,
  type GetContentLLMContextResponse,
} from "../../generated/pear-library/content.pb";

export {
  Product,
  Product_value,
} from "../../generated/pear-user-management/pear-user-management.pb";

export {
  ContentAvailability,
  ContentAvailability_value,
  type SearchContentEntity,
} from "../../generated/pear/content.pb";

export interface IContent {
  getProductContentLLMContext: (
    contentEntity: SearchContentEntity
  ) => Promise<GetContentLLMContextResponse>;
  getProductContentPreview: (
    contentEntity: SearchContentEntity
  ) => Promise<GetContentPreviewResponse>;
  openProductContentModal: (
    options: OpenProductContentModalOptions
  ) => Promise<OpenProductContentModalResponse | undefined>;
}

export type OpenProductContentModalOptions = {
  requireCanEmbed?: boolean;
  requireCanProvideLLMContext?: boolean;
};

export type OpenProductContentModalResponse = {
  contentEntity?: SearchContentEntity;
};

export enum ProductContentModalMessageType {
  OpenStandardsModal = "PRODUCT_CONTENT_MODAL_OPEN_STANDARDS_MODAL",
  OpenStandardsModalResponse = "PRODUCT_CONTENT_MODAL_STANDARDS_MODAL_RESPONSE",
}
