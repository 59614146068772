/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as PlaytimelibraryAlternateTextResponses from "./alternate-text-responses.pb"
import * as PlaytimelibraryFeedbackReferenceMaterials from "./feedback-reference-materials.pb"
import * as PlaytimelibrarySkills from "./skills.pb"
import * as PlaytimelibraryStandards from "./standards.pb"
import * as PlaytimelibraryTags from "./tags.pb"


export enum QuestionType {
  UNKNOWN = "UNKNOWN",
  TERM_DEFINITION = "TERM_DEFINITION",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
  MULTIPLE_SELECT = "MULTIPLE_SELECT",
  TEXT_RESPONSE = "TEXT_RESPONSE",
  TRUE_FALSE = "TRUE_FALSE",
  OPEN_ENDED = "OPEN_ENDED",
  DIAGRAM = "DIAGRAM",
  CLASSIFY = "CLASSIFY",
  DRAW = "DRAW",
  NUMBER_RESPONSE = "NUMBER_RESPONSE",
}

/**
 * QuestionType_value contains the numeric values of QuestionType, as defined in the
 * proto source file.
 */
export enum QuestionType_value {
  UNKNOWN = 0,
  TERM_DEFINITION = 1,
  MULTIPLE_CHOICE = 2,
  MULTIPLE_SELECT = 3,
  TEXT_RESPONSE = 4,
  TRUE_FALSE = 5,
  OPEN_ENDED = 6,
  DIAGRAM = 7,
  CLASSIFY = 8,
  DRAW = 9,
  NUMBER_RESPONSE = 10,
}

export enum Variant {
  NO_VARIANT = "NO_VARIANT",
  COOP_RADIOS = "COOP_RADIOS",
  COOP_CHECKS = "COOP_CHECKS",
  COOP_DRAGSORT = "COOP_DRAGSORT",
  COOP_TEXT_MATCH = "COOP_TEXT_MATCH",
  COOP_DRAW = "COOP_DRAW",
  COOP_TEXT_MATCH_NUMERIC = "COOP_TEXT_MATCH_NUMERIC",
}

/**
 * Variant_value contains the numeric values of Variant, as defined in the
 * proto source file.
 */
export enum Variant_value {
  NO_VARIANT = 0,
  COOP_RADIOS = 1,
  COOP_CHECKS = 2,
  COOP_DRAGSORT = 3,
  COOP_TEXT_MATCH = 4,
  COOP_DRAW = 5,
  COOP_TEXT_MATCH_NUMERIC = 6,
}

export type PracticeSetItemPracticeSetItemTag = {
  id: string
  tag: PlaytimelibraryTags.Tag | null
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type PracticeSetItemTermDefinition = {
  id: string
  practice_set_item_id: string
  term: string
  definition: string
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type PracticeSetItemMultipleChoiceMultipleChoiceChoice = {
  id: string
  practice_set_item_id: string
  text: string
  is_correct: boolean
  image_url: string
  image_alt_text: string
}

export type PracticeSetItemMultipleChoice = {
  id: string
  practice_set_item_id: string
  prompt: string
  choices: PracticeSetItemMultipleChoiceMultipleChoiceChoice[] | null
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
  image_mode: boolean
}

export type PracticeSetItemTrueFalse = {
  id: string
  practice_set_item_id: string
  prompt: string
  correct_answer: boolean
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type PracticeSetItemTextResponse = {
  id: string
  practice_set_item_id: string
  prompt: string
  correct_response: string
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
  enable_partial_credit: boolean
}

export type PracticeSetItemNumberResponse = {
  id: string
  practice_set_item_id: string
  prompt: string
  correct_response: string
  prefix: string
  suffix: string
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type PracticeSetItemOpenEnded = {
  id: string
  practice_set_item_id: string
  prompt: string
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type PracticeSetItemDiagram = {
  id: string
  practice_set_item_id: string
  prompt: string
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type PracticeSetItemDiagramLabel = {
  id: string
  practice_set_item_id: string
  label: string
  x_offset_ratio: number
  y_offset_ratio: number
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type PracticeSetItemDraw = {
  id: string
  practice_set_item_id: string
  prompt: string
  explanation: string
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
  background_image_url: string
}

export type PracticeSetItemClassifyClassifyCategoryClassifyChoice = {
  id: string
  practice_set_item_id: string
  category_id: string
  text: string
  image_url: string
  image_alt_text: string
}

export type PracticeSetItemClassifyClassifyCategory = {
  id: string
  practice_set_item_id: string
  text: string
  choices: PracticeSetItemClassifyClassifyCategoryClassifyChoice[] | null
  image_url: string
  image_alt_text: string
}

export type PracticeSetItemClassify = {
  id: string
  practice_set_item_id: string
  prompt: string
  categories: PracticeSetItemClassifyClassifyCategory[] | null
  image_mode: boolean
}

export type PracticeSetItem = {
  id: string
  is_generated: boolean
  practice_set_id: string
  order: number
  created_by: string
  question_type: QuestionType | null
  is_higher_order_thinking: boolean
  image_url: string
  video_url: string
  audio_url: string
  feedback: string
  feedback_reference_materials: PlaytimelibraryFeedbackReferenceMaterials.FeedbackReferenceMaterial[] | null
  term_definition: PracticeSetItemTermDefinition | null
  multiple_choice: PracticeSetItemMultipleChoice | null
  multiple_select: PracticeSetItemMultipleChoice | null
  true_false: PracticeSetItemTrueFalse | null
  text_response: PracticeSetItemTextResponse | null
  open_ended: PracticeSetItemOpenEnded | null
  diagram_labels: PracticeSetItemDiagramLabel[] | null
  standards: PlaytimelibraryStandards.Standard[] | null
  tags: PracticeSetItemPracticeSetItemTag[] | null
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
  alternate_text_responses: PlaytimelibraryAlternateTextResponses.AlternateTextResponse[] | null
  original_question_type: QuestionType | null
  classify: PracticeSetItemClassify | null
  draw: PracticeSetItemDraw | null
  image_alt_text: string
  feedback_image_url: string
  feedback_image_alt_text: string
  cnc_code: string
  skill_id: string
  skill: PlaytimelibrarySkills.Skill | null
  guid: string
  copied_from_item_id: string
  edited_from_item_id: string
  number_response: PracticeSetItemNumberResponse | null
  is_certified: boolean
  source_item_is_certified: boolean
  diagram: PracticeSetItemDiagram | null
  is_premium: boolean
  source_item_is_premium: boolean
}

export type CopyPracticeSetItemRequest = {
  id: string
  practice_set_id: string
  place_at_end: boolean
}

export type CreatePracticeSetItemRequest = {
  practice_set_item: PracticeSetItem | null
}

export type CreatePracticeSetItemResponse = {
  practice_set_item: PracticeSetItem | null
}

export type GetPracticeSetItemRequest = {
  id: string
}

export type GetPracticeSetItemResponse = {
  practice_set_item: PracticeSetItem | null
}

export type GetPracticeSetItemInternalRequest = {
  id: string
  use_readers: boolean
}

export type GetPracticeSetItemInternalResponse = {
  practice_set_item: PracticeSetItem | null
}

export type GetPracticeSetItemsRequest = {
  practice_set_id: string
}

export type GetPracticeSetItemsResponse = {
  practice_set_items: PracticeSetItem[] | null
}

export type UpdatePracticeSetItemRequest = {
  practice_set_item: PracticeSetItem | null
}

export type UpdatePracticeSetItemResponse = {
  practice_set_item: PracticeSetItem | null
}

export type UpdatePracticeSetItemsRequest = {
  practice_set_id: string
  practice_set_items: PracticeSetItem[] | null
}

export type ReorderPracticeSetItemsRequestItemIDAndOrder = {
  item_id: string
  order: number
}

export type ReorderPracticeSetItemsRequest = {
  practice_set_id: string
  ordering: ReorderPracticeSetItemsRequestItemIDAndOrder[] | null
}

export type UpdatePracticeSetItemsResponse = {
  practice_set_items: PracticeSetItem[] | null
}

export type DeletePracticeSetItemRequest = {
  id: string
}

export type DeletePracticeSetItemResponse = {
}

export type SearchPracticeSetItemsRequest = {
  query: string
  country: string
  limit: number
  offset: number
}

export type SearchPracticeSetItemsResponsePracticeSetItemSearchResult = {
  id: string
  question_type: QuestionType | null
  is_higher_order_thinking: boolean
  multiple_choice_prompt: string
  multiple_choice_choice_text: string
  open_ended_prompt: string
  term: string
  definition: string
  text_response_prompt: string
  text_response_correct_response: string
  diagram_label: string
  classify_prompt: string
  draw_prompt: string
  number_response_prompt: string
}

export type SearchPracticeSetItemsResponse = {
  practice_set_items: SearchPracticeSetItemsResponsePracticeSetItemSearchResult[] | null
}

export type GeneratePracticeSetItemFeedbackRequest = {
  practice_set_item: PracticeSetItem | null
}

export type GeneratePracticeSetItemFeedbackResponse = {
  feedback: string
}

export type GeneratePracticeSetItemRequest = {
  topic: string
  desired_question_types: QuestionType[] | null
  question_count: number
  practice_set_id: string
}

export type GeneratePracticeSetItemResponse = {
  practice_set_items: PracticeSetItem[] | null
}


