/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as PearusermanagementPearUserManagement from "../pear-user-management/pear-user-management.pb"


export enum ProductContentType {
  TYPE_UNKNOWN = "TYPE_UNKNOWN",
  TYPE_PEAR_DECK = "TYPE_PEAR_DECK",
  TYPE_PEAR_ASSESSMENT_TEST = "TYPE_PEAR_ASSESSMENT_TEST",
  TYPE_PEAR_PRACTICE_SET = "TYPE_PEAR_PRACTICE_SET",
  TYPE_PEAR_START_MATERIAL = "TYPE_PEAR_START_MATERIAL",
}

/**
 * ProductContentType_value contains the numeric values of ProductContentType, as defined in the
 * proto source file.
 */
export enum ProductContentType_value {
  TYPE_UNKNOWN = 0,
  TYPE_PEAR_DECK = 100,
  TYPE_PEAR_ASSESSMENT_TEST = 200,
  TYPE_PEAR_PRACTICE_SET = 300,
  TYPE_PEAR_START_MATERIAL = 400,
}

export enum ContentAvailability {
  ALL = "ALL",
  PRIVATE = "PRIVATE",
  SHARED = "SHARED",
}

/**
 * ContentAvailability_value contains the numeric values of ContentAvailability, as defined in the
 * proto source file.
 */
export enum ContentAvailability_value {
  ALL = 0,
  PRIVATE = 1,
  SHARED = 2,
}

export type SearchContentEntity = {
  product: PearusermanagementPearUserManagement.Product | null
  content_type: ProductContentType | null
  /**
   * The product unique ID for the entity.
   */
  id: string
  /**
   * The title of the entity (e.g. the practice set title, test name, or slide
   * deck title).
   */
  title: string
  /**
   * The URL at which a user may view the entity.
   */
  url: string
  /**
   * Whether the content may be embedded in an iframe. If the product is
   * able to generate a pre-signed URL for embedding the content into
   * any PDL product or interface, return true. If a user shows intent
   * to preview the entity, a request will be made to the product to
   * retrieve the pre-signed URL for use in realizing that outcome.
   */
  can_embed: boolean
  /**
   * Whether the product can provide LLM structured context for the entity
   * for use in an LLM prompt. If a user shows intent to retrieve LLM context
   * for the entity, a request will be made to the product to retrieve the
   * LLM context and the context will be provided to the user.
   */
  can_provide_llm_context: boolean
  created_at: string | null
  updated_at: string | null
}


