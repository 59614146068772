/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as PearContent from "../pear/content.pb"
import * as PlaytimelibraryPracticeSetItems from "../playtime-library/practice-set-items.pb"


export enum ReadingLevelDifferentiation {
  READING_LEVEL_UNKNOWN = "READING_LEVEL_UNKNOWN",
  READING_LEVEL_BELOW = "READING_LEVEL_BELOW",
  READING_LEVEL_AT = "READING_LEVEL_AT",
  READING_LEVEL_ABOVE = "READING_LEVEL_ABOVE",
}

/**
 * ReadingLevelDifferentiation_value contains the numeric values of ReadingLevelDifferentiation, as defined in the
 * proto source file.
 */
export enum ReadingLevelDifferentiation_value {
  READING_LEVEL_UNKNOWN = 0,
  READING_LEVEL_BELOW = 1,
  READING_LEVEL_AT = 2,
  READING_LEVEL_ABOVE = 3,
}

export enum DeckSourceIntent {
  SOURCE_INTENT_UNKNOWN = "SOURCE_INTENT_UNKNOWN",
  SOURCE_INTENT_TOPIC_INSTRUCTION = "SOURCE_INTENT_TOPIC_INSTRUCTION",
  SOURCE_INTENT_CONTENT_PREP = "SOURCE_INTENT_CONTENT_PREP",
  SOURCE_INTENT_CONTENT_REVIEW = "SOURCE_INTENT_CONTENT_REVIEW",
}

/**
 * DeckSourceIntent_value contains the numeric values of DeckSourceIntent, as defined in the
 * proto source file.
 */
export enum DeckSourceIntent_value {
  SOURCE_INTENT_UNKNOWN = 0,
  SOURCE_INTENT_TOPIC_INSTRUCTION = 1,
  SOURCE_INTENT_CONTENT_PREP = 2,
  SOURCE_INTENT_CONTENT_REVIEW = 3,
}

export enum DeckLessonType {
  LESSON_TYPE_UNKNOWN = "LESSON_TYPE_UNKNOWN",
  LESSON_TYPE_FULL = "LESSON_TYPE_FULL",
  LESSON_TYPE_MINI = "LESSON_TYPE_MINI",
  LESSON_TYPE_EXIT_TICKETS = "LESSON_TYPE_EXIT_TICKETS",
}

/**
 * DeckLessonType_value contains the numeric values of DeckLessonType, as defined in the
 * proto source file.
 */
export enum DeckLessonType_value {
  LESSON_TYPE_UNKNOWN = 0,
  LESSON_TYPE_FULL = 1,
  LESSON_TYPE_MINI = 2,
  LESSON_TYPE_EXIT_TICKETS = 3,
}

export enum AssessmentItemType {
  ITEM_TYPE_UNKNOWN = "ITEM_TYPE_UNKNOWN",
  ITEM_TYPE_MULTIPLE_CHOICE = "ITEM_TYPE_MULTIPLE_CHOICE",
  ITEM_TYPE_TRUE_FALSE = "ITEM_TYPE_TRUE_FALSE",
  ITEM_TYPE_MULTIPLE_SELECT = "ITEM_TYPE_MULTIPLE_SELECT",
}

/**
 * AssessmentItemType_value contains the numeric values of AssessmentItemType, as defined in the
 * proto source file.
 */
export enum AssessmentItemType_value {
  ITEM_TYPE_UNKNOWN = 0,
  ITEM_TYPE_MULTIPLE_CHOICE = 1,
  ITEM_TYPE_TRUE_FALSE = 2,
  ITEM_TYPE_MULTIPLE_SELECT = 3,
}

export enum AssessmentDOK {
  DOK_UNKNOWN = "DOK_UNKNOWN",
  DOK_1_RECALL = "DOK_1_RECALL",
  DOK_2_SKILL_CONCEPT = "DOK_2_SKILL_CONCEPT",
  DOK_3_STRATEGIC_THINKING = "DOK_3_STRATEGIC_THINKING",
  DOK_4_EXTENDED_THINKING = "DOK_4_EXTENDED_THINKING",
}

/**
 * AssessmentDOK_value contains the numeric values of AssessmentDOK, as defined in the
 * proto source file.
 */
export enum AssessmentDOK_value {
  DOK_UNKNOWN = 0,
  DOK_1_RECALL = 1,
  DOK_2_SKILL_CONCEPT = 2,
  DOK_3_STRATEGIC_THINKING = 3,
  DOK_4_EXTENDED_THINKING = 4,
}

export enum AssessmentDifficulty {
  DIFFICULTY_UNKNOWN = "DIFFICULTY_UNKNOWN",
  DIFFICULTY_EASY = "DIFFICULTY_EASY",
  DIFFICULTY_MEDIUM = "DIFFICULTY_MEDIUM",
  DIFFICULTY_HARD = "DIFFICULTY_HARD",
}

/**
 * AssessmentDifficulty_value contains the numeric values of AssessmentDifficulty, as defined in the
 * proto source file.
 */
export enum AssessmentDifficulty_value {
  DIFFICULTY_UNKNOWN = 0,
  DIFFICULTY_EASY = 1,
  DIFFICULTY_MEDIUM = 2,
  DIFFICULTY_HARD = 3,
}

export enum GenerateContentResponseStatus {
  STATUS_UNKNOWN = "STATUS_UNKNOWN",
  STATUS_READING_INPUT = "STATUS_READING_INPUT",
  STATUS_STARTING_GENERATION = "STATUS_STARTING_GENERATION",
  STATUS_ITEM_COMPLETE = "STATUS_ITEM_COMPLETE",
  STATUS_ITEM_ERROR = "STATUS_ITEM_ERROR",
  STATUS_DONE = "STATUS_DONE",
  STATUS_PING = "STATUS_PING",
}

/**
 * GenerateContentResponseStatus_value contains the numeric values of GenerateContentResponseStatus, as defined in the
 * proto source file.
 */
export enum GenerateContentResponseStatus_value {
  STATUS_UNKNOWN = 0,
  STATUS_READING_INPUT = 1,
  STATUS_STARTING_GENERATION = 2,
  STATUS_ITEM_COMPLETE = 3,
  STATUS_ITEM_ERROR = 4,
  STATUS_DONE = 5,
  STATUS_PING = 6,
}

export type GenerateContentRequestGenerateContent = {
  request_id: string
  type: PearContent.ProductContentType | null
  reading_level_differentiation: ReadingLevelDifferentiation | null
}

export type GenerateContentRequestPracticeSetConfig = {
  question_types: PlaytimelibraryPracticeSetItems.QuestionType[] | null
  min_question_count: number
  max_question_count: number
  extract_questions: boolean
}

export type GenerateContentRequestDeckConfig = {
  source_intent: DeckSourceIntent | null
  lesson_type: DeckLessonType | null
}

export type GenerateContentRequestAssessmentConfig = {
  item_type: AssessmentItemType | null
  num_items: number
  dok: AssessmentDOK | null
  difficulty: AssessmentDifficulty | null
  test_name: string
}

export type GenerateContentRequest = {
  requests: GenerateContentRequestGenerateContent[] | null
  /**
   * Source inputs for content generation, common to all products
   */
  topic: string
  context: string
  source_urls: string[]
  upload_urls: string[]
  /**
   * Configs common to all products
   */
  grade_level_id: string
  subject_id: string
  standard_ids: string[]
  /**
   * Product-specific configs
   */
  practice_set_config: GenerateContentRequestPracticeSetConfig | null
  deck_config: GenerateContentRequestDeckConfig | null
  assessment_config: GenerateContentRequestAssessmentConfig | null
}

export type GenerateContentResponse = {
  request_id: string
  status: GenerateContentResponseStatus | null
  generated_content_url: string
  generated_content_id: string
  generated_content_title: string
  generated_content_num_items: number
}


