/* eslint-disable */
// @ts-nocheck
/*
 * This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
 */

import * as fm from "../fetch.pb"
import * as PearusermanagementAcademicSession from "./academic_session.pb"
import * as PearusermanagementBatch from "./batch.pb"
import * as PearusermanagementClass from "./class.pb"
import * as PearusermanagementClassEnrollment from "./class_enrollment.pb"
import * as PearusermanagementCommon from "./common.pb"
import * as PearusermanagementCourse from "./course.pb"
import * as PearusermanagementDemographic from "./demographic.pb"
import * as PearusermanagementOrg from "./org.pb"
import * as PearusermanagementUser from "./user.pb"
import * as PearusermanagementWorkspace from "./workspace.pb"


export enum ThirdParty {
  INVALID_THIRD_PARTY = "INVALID_THIRD_PARTY",
  CLEVER = "CLEVER",
  GOOGLE = "GOOGLE",
  MICROSOFT = "MICROSOFT",
}

/**
 * ThirdParty_value contains the numeric values of ThirdParty, as defined in the
 * proto source file.
 */
export enum ThirdParty_value {
  INVALID_THIRD_PARTY = 0,
  CLEVER = 1,
  GOOGLE = 2,
  MICROSOFT = 3,
}

export enum Product {
  INVALID_PRODUCT = "INVALID_PRODUCT",
  EDULASTIC = "EDULASTIC",
  GIANT_STEPS = "GIANT_STEPS",
  PEAR_DECK = "PEAR_DECK",
  TUTOR_ME = "TUTOR_ME",
  ASSISTANT = "ASSISTANT",
}

/**
 * Product_value contains the numeric values of Product, as defined in the
 * proto source file.
 */
export enum Product_value {
  INVALID_PRODUCT = 0,
  EDULASTIC = 1,
  GIANT_STEPS = 2,
  PEAR_DECK = 3,
  TUTOR_ME = 4,
  ASSISTANT = 5,
}

export type UpsertUserInternalRequest = {
  user: PearusermanagementUser.User | null
}

export type UpsertUserInternalResponse = {
  user: PearusermanagementUser.User | null
}

export type GetUserInternalRequest = {
  user_id: string
}

export type GetUserInternalResponse = {
  user: PearusermanagementUser.User | null
  user_products: UserProduct[] | null
}

export type UserProduct = {
  product: Product | null
  product_user_id: string
}

export type ProductAccessData = {
  product: Product | null
  has_user: boolean
  has_license: boolean
  can_create_user: boolean
  redirect_url: string
  product_user_id: string
  is_user_student: boolean
  is_user_initialized: boolean
}

export type GetUserRequest = {
}

export type GetUserResponse = {
  user: PearusermanagementUser.User | null
  product_access_data: ProductAccessData[] | null
}

export type UpsertUserThirdPartyTokenInternalRequest = {
  token: UserThirdPartyToken | null
}

export type UpsertUserThirdPartyTokenInternalResponse = {
  token: UserThirdPartyToken | null
}

export type UserThirdPartyToken = {
  id: string
  user_id: string
  third_party: ThirdParty | null
  foreign_id: string
  oauth_access_token: string
  oauth_refresh_token: string
  oauth_access_token_expiration: string | null
  created_at: string | null
  updated_at: string | null
  deleted_at: string | null
}

export type ListUserThirdPartyTokensInternalRequest = {
  user_id: string
}

export type ListUserThirdPartyTokensInternalResponse = {
  tokens: UserThirdPartyToken[] | null
}

export type InitializeUserProductRequest = {
  target_product: Product | null
}

export type InitializeUserProductResponse = {
  redirect_url: string
}

export class PearUserManagement {
  /**
   * Pre-rostering User Management operations
   */
  static GetUser(req: GetUserRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<GetUserResponse>> {
    return fm.fetchReq<GetUserRequest, GetUserResponse>(`/pearusermanagement.PearUserManagement/GetUser`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertUserInternal(req: UpsertUserInternalRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<UpsertUserInternalResponse>> {
    return fm.fetchReq<UpsertUserInternalRequest, UpsertUserInternalResponse>(`/pearusermanagement.PearUserManagement/UpsertUserInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetUserInternal(req: GetUserInternalRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<GetUserInternalResponse>> {
    return fm.fetchReq<GetUserInternalRequest, GetUserInternalResponse>(`/pearusermanagement.PearUserManagement/GetUserInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  /**
   * Third party tokens
   */
  static ListUserThirdPartyTokensInternal(req: ListUserThirdPartyTokensInternalRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<ListUserThirdPartyTokensInternalResponse>> {
    return fm.fetchReq<ListUserThirdPartyTokensInternalRequest, ListUserThirdPartyTokensInternalResponse>(`/pearusermanagement.PearUserManagement/ListUserThirdPartyTokensInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertUserThirdPartyTokenInternal(req: UpsertUserThirdPartyTokenInternalRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<UpsertUserThirdPartyTokenInternalResponse>> {
    return fm.fetchReq<UpsertUserThirdPartyTokenInternalRequest, UpsertUserThirdPartyTokenInternalResponse>(`/pearusermanagement.PearUserManagement/UpsertUserThirdPartyTokenInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  /**
   * User products
   */
  static InitializeUserProduct(req: InitializeUserProductRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<InitializeUserProductResponse>> {
    return fm.fetchReq<InitializeUserProductRequest, InitializeUserProductResponse>(`/pearusermanagement.PearUserManagement/InitializeUserProduct`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  /**
   * Rostering Upserts
   */
  static UpsertWorkspaceExternalService(req: PearusermanagementWorkspace.UpsertWorkspaceRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementWorkspace.UpsertWorkspaceResponse>> {
    return fm.fetchReq<PearusermanagementWorkspace.UpsertWorkspaceRequest, PearusermanagementWorkspace.UpsertWorkspaceResponse>(`/pearusermanagement.PearUserManagement/UpsertWorkspaceExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertUserExternalService(req: PearusermanagementUser.UpsertUserExternalRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementUser.UpsertUserExternalResponse>> {
    return fm.fetchReq<PearusermanagementUser.UpsertUserExternalRequest, PearusermanagementUser.UpsertUserExternalResponse>(`/pearusermanagement.PearUserManagement/UpsertUserExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertOrgExternalService(req: PearusermanagementOrg.UpsertOrgRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementOrg.UpsertOrgResponse>> {
    return fm.fetchReq<PearusermanagementOrg.UpsertOrgRequest, PearusermanagementOrg.UpsertOrgResponse>(`/pearusermanagement.PearUserManagement/UpsertOrgExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertCourseExternalService(req: PearusermanagementCourse.UpsertCourseRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementCourse.UpsertCourseResponse>> {
    return fm.fetchReq<PearusermanagementCourse.UpsertCourseRequest, PearusermanagementCourse.UpsertCourseResponse>(`/pearusermanagement.PearUserManagement/UpsertCourseExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertClassExternalService(req: PearusermanagementClass.UpsertClassRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementClass.UpsertClassResponse>> {
    return fm.fetchReq<PearusermanagementClass.UpsertClassRequest, PearusermanagementClass.UpsertClassResponse>(`/pearusermanagement.PearUserManagement/UpsertClassExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertClassEnrollmentExternalService(req: PearusermanagementClassEnrollment.UpsertClassEnrollmentRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementClassEnrollment.UpsertClassEnrollmentResponse>> {
    return fm.fetchReq<PearusermanagementClassEnrollment.UpsertClassEnrollmentRequest, PearusermanagementClassEnrollment.UpsertClassEnrollmentResponse>(`/pearusermanagement.PearUserManagement/UpsertClassEnrollmentExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertDemographicExternalService(req: PearusermanagementDemographic.UpsertDemographicRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementDemographic.UpsertDemographicResponse>> {
    return fm.fetchReq<PearusermanagementDemographic.UpsertDemographicRequest, PearusermanagementDemographic.UpsertDemographicResponse>(`/pearusermanagement.PearUserManagement/UpsertDemographicExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static UpsertAcademicSessionExternalService(req: PearusermanagementAcademicSession.UpsertAcademicSessionRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementAcademicSession.UpsertAcademicSessionResponse>> {
    return fm.fetchReq<PearusermanagementAcademicSession.UpsertAcademicSessionRequest, PearusermanagementAcademicSession.UpsertAcademicSessionResponse>(`/pearusermanagement.PearUserManagement/UpsertAcademicSessionExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  /**
   * Rostering Deletes
   */
  static DeleteUserExternalService(req: PearusermanagementCommon.DeleteRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementCommon.DeleteResponse>> {
    return fm.fetchReq<PearusermanagementCommon.DeleteRequest, PearusermanagementCommon.DeleteResponse>(`/pearusermanagement.PearUserManagement/DeleteUserExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteOrgExternalService(req: PearusermanagementCommon.DeleteRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementCommon.DeleteResponse>> {
    return fm.fetchReq<PearusermanagementCommon.DeleteRequest, PearusermanagementCommon.DeleteResponse>(`/pearusermanagement.PearUserManagement/DeleteOrgExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteCourseExternalService(req: PearusermanagementCommon.DeleteRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementCommon.DeleteResponse>> {
    return fm.fetchReq<PearusermanagementCommon.DeleteRequest, PearusermanagementCommon.DeleteResponse>(`/pearusermanagement.PearUserManagement/DeleteCourseExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteClassEnrollmentExternalService(req: PearusermanagementCommon.DeleteRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementCommon.DeleteResponse>> {
    return fm.fetchReq<PearusermanagementCommon.DeleteRequest, PearusermanagementCommon.DeleteResponse>(`/pearusermanagement.PearUserManagement/DeleteClassEnrollmentExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteClassExternalService(req: PearusermanagementCommon.DeleteRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementCommon.DeleteResponse>> {
    return fm.fetchReq<PearusermanagementCommon.DeleteRequest, PearusermanagementCommon.DeleteResponse>(`/pearusermanagement.PearUserManagement/DeleteClassExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteDemographicExternalService(req: PearusermanagementCommon.DeleteRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementCommon.DeleteResponse>> {
    return fm.fetchReq<PearusermanagementCommon.DeleteRequest, PearusermanagementCommon.DeleteResponse>(`/pearusermanagement.PearUserManagement/DeleteDemographicExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteAcademicSessionExternalService(req: PearusermanagementCommon.DeleteRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementCommon.DeleteResponse>> {
    return fm.fetchReq<PearusermanagementCommon.DeleteRequest, PearusermanagementCommon.DeleteResponse>(`/pearusermanagement.PearUserManagement/DeleteAcademicSessionExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  /**
   * Rostering Batch Operations
   */
  static ApplyRosteringBatchInternal(req: PearusermanagementBatch.BatchRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementBatch.BatchResponse>> {
    return fm.fetchReq<PearusermanagementBatch.BatchRequest, PearusermanagementBatch.BatchResponse>(`/pearusermanagement.PearUserManagement/ApplyRosteringBatchInternal`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ApplyRosteringBatchExternalService(req: PearusermanagementBatch.BatchRequest, initReq?: fm.InitReq): Promise<fm.GrpcResponse<PearusermanagementBatch.BatchResponse>> {
    return fm.fetchReq<PearusermanagementBatch.BatchRequest, PearusermanagementBatch.BatchResponse>(`/pearusermanagement.PearUserManagement/ApplyRosteringBatchExternalService`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}

