import { Product } from "./content";

export interface IIdentity {
  identifyProduct: (product: Product) => void;
  identifyUser: (userIdentityToken: string) => void;
  openInitializeProductModal: (
    options: OpenInitializeProductModalOptions
  ) => void;
  setToken: (token: string) => void;
}

export type OpenInitializeProductModalOptions = {
  email: string;
  product: Product;
  followRedirectInModal?: boolean;
};

export type OpenInitializeProductModalResponse = {
  initialized: boolean;
};

export enum SignInIntent {
  InitUserTeacher = "init_user_teacher",
}
